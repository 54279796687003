<template>
    <div>
        <div v-if="this.currentCarId !== null" class="row">
                <div class="col-lg-3 col-md-6">
                  <!-- Add the bg color to the header using any of the bg-* classes -->
                  <div class="box box-widget widget-user-2">
                    <div class="widget-user-header" :class="editTgl ? 'bg-default' : 'bg-green'">
                      <!-- <button v-if="!user.cus" type="button" class="btn btn-default pull-right" style="border: none;" @click="editTgl = !editTgl"><span><i class="fa fa-edit"></i></span></button> -->
                      <div v-if="!editTgl">
                        <h3 style="margin-left: 0;" class="widget-user-username">{{ currentCar.name }}
                          <span class="label pull-right" :style="`border: solid 2px #EEEFF4;background-color: ${currentCar.color}; color: ${currentCar.color}`">-</span>
                        </h3>
                        <h5 class="widget-user-desc" style="margin-left: 0;cursor: pointer" @click="makeCurrentCus(currentCar.customerid)" >{{ currentCar.company_name }}</h5>
                      </div>
                      <h3 v-else style="margin-left: 0px;" class="widget-user-username">
                          <div class="row">
                              <span class="col-xs-10" :style="editTgl ? 'margin-top: 0px' : 'margin-top: 3px'" style="padding-left: 5px">

                                  <div v-if="editTgl" class="form-group col-xs-12" :class="{'has-error': $v.formResponses.name.$error}" style="padding-left: 0px">
                                          <input id="inputName" class="form-control input-lg"  v-model="$v.formResponses.name.$model" type="text">
                                          <span v-if="!$v.formResponses.name.required" class="help-block">Povinný údaj</span>
                                          <span v-if="!$v.formResponses.name.maxLength" class="help-block">Může mít maximálně 32 znaků!</span>
                                  </div>
                                  <span v-else>{{currentCar.name}}</span>
                              </span>
                              <span class="col-xs-2" style="margin-top: 5px">
                                  <swatches :trigger-style="{ width: '35px', height: '35px', border: '2px solid #EEEFF4' }" v-model="color" colors="text-advanced" popover-to="left"></swatches>
                              </span>
                          </div>
                      </h3>
                    </div>
                  </div>
                  <div  class="box box-widget widget-user-2">
                    <div v-if="!user.cus && !editTgl" class="box-footer no-border">
                            <div class="pull-right">
                                <button v-if="!user.cus" @click="editCar()" type="button" class="btn btn-default" style="border: none; margin-left: 10px"><span><i class="fa fa-edit"></i> Upravit</span></button>
                            </div>
                        </div>
                        <div class="box-footer no-padding">
                            <ul class="nav nav-stacked">
                                <li v-if="currentCar.year_made || editTgl"><a style="cursor: auto" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Rok výroby</span>
                                        <span class="col-xs-7">
                                            <select v-if="editTgl" class="form-control input-sm col-xs-6" v-model="yearMade">
                                                <option v-for="year in yearMadeOptions" :key="year" :value="year">{{year}}</option>
                                            </select>
                                            <span v-else class="pull-right">{{currentCar.year_made}}</span>
                                        </span>
                                    </div>
                                </a></li>
                                <li v-if="!editTgl"><a href="#" @click="(e) => {e.preventDefault(); showByStage = 1 }">Plánované úkony <span class="pull-right"><span class="badge bg-blue">{{currentCar.planned_issue_num}}</span></span></a></li>
                                <li v-if="!editTgl"><a href="#" @click="(e) => {e.preventDefault(); showByStage = 3 }">Hotové úkony <span class="pull-right"><span class="badge bg-green">{{currentCar.done_issue_num}}</span></span></a></li>
                                <li v-if="!user.cus">
                                    <a v-if="editTgl" href="#" @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">Zákazník</span>
                                            <span class="col-xs-7">
                                                <select class="form-control input-sm col-xs-6" v-model="$v.formResponses.customerid.$model" @change="getMinMnt">
                                                    <option v-for="cus in minCustomers" :key="cus.id" :value="cus.id">{{cus.text}}</option>
                                                </select>
                                                <span v-if="!$v.formResponses.customerid.required" class="help-block">Povinný údaj</span>
                                            </span>
                                         </div>
                                    </a>
                                    <a v-else href="#" @click="makeCurrentCus(currentCar.customerid)">Zákazník<a class="pull-right">{{currentCar.company_name}}</a></a>
                                </li>
                                <li>
                                    <a v-if="editTgl" href="#"  @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">Řidič</span>
                                            <span class="col-xs-7">
                                                <select class="form-control input-sm col-xs-6" v-model="$v.formResponses.maintainerid.$model">
                                                    <option v-for="mnt in minMaintainers" :key="mnt.id" :value="mnt.id">{{mnt.text}}</option>
                                                </select>
                                                <span v-if="!$v.formResponses.maintainerid.required" class="help-block">Povinný údaj</span>
                                            </span>
                                         </div>
                                    </a>
                                    <a v-else style="cursor: auto" @click="makeCurrentMnt(currentCar.maintainerid)">Řidič<a class="pull-right">{{currentCar.fullname}}</a></a>
                                </li>
                                <li v-if="currentCar.licence_plate || editTgl">
                                    <a style="cursor: auto" @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">SPZ</span>
                                            <span class="col-xs-7">
                                                <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.licence_plate.$error}">
                                                    <input type="text" class="form-control input-sm" v-model="$v.formResponses.licence_plate.$model">
                                                    <span v-if="!$v.formResponses.licence_plate.minLength" class="help-block">Musí mít minimálně 5 znaků!</span>
                                                    <span v-if="!$v.formResponses.licence_plate.maxLength" class="help-block">Může mít maximálně 8 znaků!</span>
                                                </div>
                                                <span v-else class="pull-right" style="-webkit-user-select: all; -ms-user-select: all; user-select: all;">{{currentCar.licence_plate}}</span>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li v-if="currentCar.vin_code || editTgl"><a  style="cursor: auto"  @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">VIN</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.vin_code.$error}">
                                                <input type="text" class="form-control input-sm" v-model="$v.formResponses.vin_code.$model">
                                                <span v-if="!$v.formResponses.vin_code.minLength || !$v.formResponses.vin_code.maxLength" class="help-block">Musí mít 17 znaků!</span>
                                            </div>
                                            <span v-else class="pull-right">{{currentCar.vin_code}}</span>
                                        </span>
                                    </div>
                                </a></li>
                                <li v-if="currentCar.engine_code || editTgl">
                                    <a style="cursor: auto" @click="(e) => e.preventDefault()">
                                        <div class="row">
                                            <span class="col-xs-5">Kód motoru</span>
                                            <span class="col-xs-7">
                                                <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.engine_code.$error}">
                                                    <input type="text" class="form-control input-sm" v-model="$v.formResponses.engine_code.$model">
                                                    <span v-if="!$v.formResponses.engine_code.maxLength" class="help-block">Může mít maximálně 8 znaků!</span>
                                                </div>
                                                <span v-else class="pull-right" style="-webkit-user-select: all; -ms-user-select: all; user-select: all;">{{currentCar.engine_code}}</span>
                                            </span>
                                        </div>
                                    </a>
                                </li>
                                <li v-if="currentCar.displacement || editTgl"><a style="cursor: auto" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Objem motoru [ccm]</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.displacement.$error}">
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-btn">
                                                        <button @click="addDisplacement()" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input @focus="displacementNewVal()" id="inputDisplacement" type="number" class="form-control input-sm" v-model="$v.formResponses.displacement.$model" min="500" max="7000">
                                                    <span class="input-group-btn">
                                                        <button @click="subDisplacement()" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-minus"></i></button>
                                                    </span>
                                                </div>
                                                <span v-if="!$v.formResponses.displacement.integer" class="help-block">Musí být celé číslo!</span>
                                                <span v-if="!$v.formResponses.displacement.between" class="help-block">Může být pouze v rozmezí od 50 ccm do 7000 ccm.</span>
                                            </div>
                                            <span v-else class="pull-right">{{currentCar.displacement}}</span>
                                        </span>
                                    </div>
                                </a></li>
                                <li v-if="currentCar.engine_power || editTgl"><a style="cursor: auto" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Výkon motoru [kW]</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.engine_power.$error}">
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-btn">
                                                        <button @click="addPower()" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input id="inputPower" type="number" class="form-control input-sm" v-model="$v.formResponses.engine_power.$model" min="1" max="1000">
                                                    <span class="input-group-btn">
                                                        <button @click="subPower()" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-minus"></i></button>
                                                    </span>
                                                </div>
                                                <span v-if="!$v.formResponses.engine_power.integer" class="help-block">Musí být celé číslo!</span>
                                                <span v-if="!$v.formResponses.engine_power.between" class="help-block">Může být pouze v rozmezí od 1 kW do 1000 kW.</span>
                                            </div>
                                            <span v-else class="pull-right">{{currentCar.engine_power}}</span>
                                        </span>
                                    </div>
                                </a></li>
                                <li v-if="currentCar.kilometers || editTgl"><a style="cursor: auto" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">Najeto [km]</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" class="form-group" :class="{'has-error': $v.formResponses.kilometers.$error}">
                                                <!-- <input type="number" class="form-control input-sm" v-model="$v.formResponses.kilometers.$model"> -->
                                                <div class="input-group input-group-sm">
                                                    <span class="input-group-btn">
                                                        <button @click="addKilometers" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-plus"></i></button>
                                                    </span>
                                                    <input id="inputDisplacement" type="number" class="form-control input-sm" v-model="$v.formResponses.kilometers.$model" min="0" max="2000000">
                                                    <span class="input-group-btn">
                                                        <button @click="subKilometers" type="button" class="btn btn-default btn-flat btn-sm"><i class="fa fa-minus"></i></button>
                                                    </span>
                                                </div>
                                                <span v-if="!$v.formResponses.kilometers.integer" class="help-block">Musí být celé číslo!</span>
                                                <span v-if="!$v.formResponses.kilometers.between" class="help-block">Musí být mezi 0 a 2 000 000!</span>
                                            </div>
                                            <span v-else class="pull-right">{{currentCar.kilometers}}</span>
                                        </span>
                                    </div>
                                </a></li>
                                <li v-if="currentCar.stk_expire || editTgl"><a style="cursor: auto" @click="(e) => e.preventDefault()">
                                    <div class="row">
                                        <span class="col-xs-5">STK do</span>
                                        <span class="col-xs-7">
                                            <div v-if="editTgl" style="display: flex; align-items: center">
                                                <select class="form-control input-sm" v-model="$v.formResponses.stk_year.$model">
                                                    <option v-for="year in yearStkOptions" :key="year" :value="year">{{year}}</option>
                                                </select>
                                                <span v-if="!$v.formResponses.stk_year.required" class="help-block">Povinný údaj</span>
                                                <span style="margin-left: 0.5rem; margin-right: 0.5rem">/</span>
                                                <select class="form-control input-sm" v-model="$v.formResponses.stk_month.$model">
                                                    <option v-for="month in monthStkOptions" :key="month" :value="month">{{month}}</option>
                                                </select>
                                                <span v-if="!$v.formResponses.stk_month.required" class="help-block">Povinný údaj</span>
                                            </div>
                                            <span v-else class="pull-right">{{getSTKDate(currentCar.stk_expire)}}</span>
                                        </span>
                                    </div>

                                </a></li>
                            </ul>
                        </div>
                        <div v-if="editTgl" class="box-footer" style="padding-top: 10px">
                            <button type="button" class="btn btn-success" @click="carUpdate()" :disabled="$v.formResponses.$invalid">Uložit změny</button> <br>
                            <div style="margin-top: 10px">
                                <button type="button" class="btn btn-danger btn-sm" @click="carDelete()">Odstranit záznam</button>
                                <button type="button" class="btn btn-default btn-sm pull-right" @click="editTgl = false">Zrušit úpravy</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-md-6">
                <div class="nav-tabs-custom">
                    <ul class="nav nav-tabs">
                        <li class="active"><a href="#issues_sub" data-toggle="tab"><i class="fa fa-tasks"></i> Úkony</a></li>
                        <li v-if="!user.cus"><a href="#comments_sub" data-toggle="tab"><i class="fa fa-comments-o"></i> Komentáře</a></li>
                        <li><a href="#files_sub" data-toggle="tab"><i class="fa fa-file-o"></i> Soubory</a></li>
                    </ul>
                    <div class="tab-content">
                        <div class="active tab-pane" id="issues_sub">
                            <div class="row">
                                <div class="col-lg-4" style="margin-top: 5px">
                                    <div v-if="currentCar.issues != ''" class="input-group input-group-sm" style="width: 100%">
                                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                                    </div>
                                </div>
                                <div class="col-lg-4" style="margin-top: 5px">
                                    <div v-if="currentCar.issues != ''" class="input-group input-group-sm" style="width: 100%">
                                        <!-- <div class="input-group-btn" style="margin-right: 5px"> -->
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 1, 'btn-primary': showByStage == 1}" @click="showByStage = 1">Plánováno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 4, 'btn-primary': showByStage == 4}" @click="showByStage = 4">Otevřeno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 3, 'btn-primary': showByStage == 3}" @click="showByStage = 3">Uzavřeno</button>
                                            <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByStage == 0, 'btn-primary': showByStage == 0}" @click="showByStage = 0">Všechny</button>
                                        <!-- </div> -->
                                    </div>
                                </div>
                                <div class="col-lg-4" style="margin-top: 5px">
                                    <div  v-if="!user.cus" class="input-group input-group-sm pull-right">
                                        <div class="input-group-btn pull-right" style="margin-right: 5px">
                                            <router-link :to="{ name: 'IssueAdd', params: { carid: currentCar.carid, customerid: currentCar.customerid, maintainerid: currentCar.maintainerid } }" class="btn btn-default btn-flat pull-right"><i class="fa fa-plus"></i> Přidat úkon</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div v-if="currentCar.issues != ''" class="table-responsive">
                                <table class="table table-hover">
                                <thead>
                                <tr>
                                    <th style="width: 10%">Stav</th>
                                    <th style="width: 40%">Úkon</th>
                                    <th style="width: 15%">Od</th>
                                    <th>Do</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="issue in rows.filter((item) => item.tagid == showByStage || showByStage == 0 || (showByStage == 2 && item.tagid != 1 && item.tagid != 3))" :key="issue.issueid">
                                        <td><span class="label" :style="`background-color: ${issue.color};`">{{issue.title}}</span></td>
                                        <td><a @click="makeCurrentIssue(issue.issueid)">{{issue.name}}</a></td>
                                        <td>{{getTime(issue.due_from)}}</td>
                                        <td>{{getTime(issue.due_to)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>

                            <!-- </div> -->

                            <div v-else class="callout callout-info">
                                <p><i class="icon fa fa-info"></i> U tohoto auta nejsou evidovány žádné úkony.</p>
                            </div>
                        </div>
                        <!-- /.tab-pane -->

                        <div class="tab-pane" id="comments_sub">
                            <div v-if="commentResponses.comment != null">
                                <span class="pull-right text-muted">({{commentResponses.comment.length}}/1024)</span>
                                <textarea id="inputComment" v-model="$v.commentResponses.comment.$model" style="resize: none;" class="form-control" rows="6" placeholder="Komentář k autu" maxlength="1024" :readonly="user.cus == 1"></textarea>
                            </div>
                            <br>
                            <div v-if="commentResponses.hidden_comment != null && !user.cus">
                                <strong><i class="fa fa-user-secret"></i> Skrytý komentář:</strong><span class="pull-right text-muted">({{commentResponses.hidden_comment.length}}/1024)</span>
                                <textarea id="inputHiddenComment" v-model="$v.commentResponses.hidden_comment.$model" style="resize: none;" class="form-control" rows="6" placeholder="Komentář k autu, který neuvidí zákazník" maxlength="1024"></textarea>
                            </div>
                            <br>
                            <div v-if="!user.cus">
                                <button type="button" class="btn btn-success btn-flat" @click="commentUpdate()" :disabled="$v.commentResponses.$invalid || (commentResponses.comment == currentCar.comment && commentResponses.hidden_comment == currentCar.hidden_comment)">Uložit komentář</button>
                            </div>
                        </div>

                        <div class="tab-pane" id="files_sub">
                            <div class="row">
                                <div class="col-lg-4" style="margin-top: 5px">
                                    <div class="input-group input-group-sm" style="width: 100%">
                                        <input v-model="filesFilter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                                    </div>
                                </div>
                                <div class="col-lg-4" style="margin-top: 5px">
                                    <div class="input-group input-group-sm" style="width: 100%">
                                        <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByFileRelation == 1, 'btn-primary': showByFileRelation == 1}" @click="showByFileRelation = 1">Auto</button>
                                        <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByFileRelation == 2, 'btn-primary': showByFileRelation == 2}" @click="showByFileRelation = 2">Úkony</button>
                                        <button type="button" class="btn btn-default btn-flat btn-sm" style="width: 25%" :class="{'active': showByFileRelation == 0, 'btn-primary': showByFileRelation == 0}" @click="showByFileRelation = 0">Všechny</button>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving || isSuccess">
                                <h3>Nahrát soubory</h3>
                                <div class="dropbox">
                                    <input type="file" multiple name="files" :disabled="isSaving" @change="filesChange($event.target.files); fileCount = $event.target.files.length"
                                    accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt" class="input-file">
                                    <p v-if="isInitial">
                                        Přetáhněte soubory do boxu<br> nebo klikněte pro výběr souborů
                                    </p>
                                    <p v-if="isSaving">
                                        Nahrávání {{ fileCount }} souborů...
                                    </p>
                                    <p v-if="isSuccess">
                                        Nahráno {{ fileCount }} souborů<br> klikněte znovu pro nahrání dalších souborů
                                    </p>
                                </div>
                            </form>
                            <div v-if="allCarFiles != ''" class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%">Soubor</th>
                                            <th style="width: 5%">Číslo úkonu</th>
                                            <th style="width: 5%">Nahráno</th>
                                            <th style="width: 15%">Akce</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    <tr v-for="file in fileRows.filter((file) => showByFileRelation == 0 || (showByFileRelation == 1 && file.issueid == undefined) || (showByFileRelation == 2 && file.issueid != undefined) )" :key="file.fileid">
                                        <td><a :href="filesUrl + currentCarId + '/' + file.filename" :download="file.originalname">{{file.originalname}}</a></td>
                                        <td><a @click="makeCurrentIssue(file.issueid)" v-if="file.issue_visible_id != null">{{file.issue_visible_id}}</a></td>
                                        <td>{{getTime(file.uploaded)}}</td>
                                        <td>
                                            <a @click="deleteFile(file.fileid)"><i class="fa fa-trash" data-toggle="tooltip" data-placement="top" title="Odstranit"></i></a>
                                            <a v-if="!user.cus && file.maintainerid === null && file.visible == 0" @click="toggleVisibility(file.fileid)" style="margin-left: 0.7rem"><i class="fa fa-eye" data-toggle="tooltip" data-placement="top" title="Zviditelnit pro zákazníky"></i></a>
                                            <a v-if="!user.cus && file.maintainerid === null && file.visible == 1" @click="toggleVisibility(file.fileid)" style="margin-left: 0.7rem"><i class="fa fa-eye-slash" data-toggle="tooltip" data-placement="top" title="Skrýt pro zákazníky"></i></a> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>

                            <!-- </div> -->

                            <div v-else class="callout callout-info">
                                <p><i class="icon fa fa-info"></i> U tohoto auta nejsou evidovány žádné soubory.</p>
                            </div>
                        </div>
                        <!-- /.tab-pane -->
                    </div>
                    <!-- /.tab-content -->
                </div>
                <!-- /.nav-tabs-custom -->
                </div>
                <!-- /.col -->
            </div>
    </div>
</template>

<script>
const name = 'CarDetail';

// Vuelidate
import { required, minLength, maxLength, integer, between, requiredIf } from 'vuelidate/lib/validators'

// Import color picker module
import Swatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.min.css";

import router from '../router';

import { mapGetters, mapActions } from "vuex";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    name,
    components: {
        Swatches
    },
    data() {
        return {

            filesUrl: process.env.VUE_APP_FILES,

            filter: '',
            filesFilter: '',
            page: null,
            updated: false,

            //Predefined values
            yearStkOptions: [],
            monthStkOptions: [],
            yearMadeOptions: [],

            commentResponses: {
                comment: '',
                hidden_comment: ''
            },

            formResponses: {
                name: '',
                customerid: null,
                maintainerid: null,
                licence_plate: '',
                vin_code: '',
                engine_code: '',
                engine_power: null,
                kilometers: '',
                stk_year: null,
                stk_month: null,
                displacement: null,
            },
            yearMade: null,

            showByStage: 0,
            showByFileRelation: 0,

            // Toggles
            editTgl: false,
            // Values to be edited
            color: null,

            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,

        }
    },
    validations: {
        formResponses: {
            name: {
                required,
                maxLength: maxLength(32)
            },
            customerid: {
                required
            },
            maintainerid: {
                required
            },
            licence_plate: {
                minLength: minLength(5),
                maxLength: maxLength(8)
            },
            vin_code: {
                minLength: minLength(17),
                maxLength: maxLength(17)
            },
            engine_code: {
                maxLength: maxLength(8)
            },
            engine_power: {
                integer,
                between: between(1, 1000)
            },
            kilometers: {
                integer,
                between: between(0, 2000000)
            },
            stk_year: {
                required: requiredIf(function() {
                    return this.formResponses.stk_month
                })
            },
            stk_month: {
                required: requiredIf(function() {
                    return this.formResponses.stk_year
                })
            },
            displacement: {
                integer,
                between: between(50, 7000)
            },
            comment: {
                maxLength: maxLength(1024)
            },
            hidden_comment: {
                maxLength: maxLength(1024)
            }
        },
        commentResponses: {
            comment: {
                maxLength: maxLength(1024)
            },
            hidden_comment: {
                maxLength: maxLength(1024)
            }
        }
    },
    computed: {
        ...mapGetters(['getAllPages', 'minCustomers', 'minMaintainers', 'currentCar', 'currentCarId', 'user', 'allCarFiles']),

        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },

        fileRows () {
            if (!this.allCarFiles) {
                return []
            }

            if (!this.allCarFiles.length) {
                return []
            }

            const filteredRows =  this.allCarFiles.filter(item => {
                return (this.$helpers.filterCheck(this.filesFilter, item.originalname) || (item.issue_visible_id != null && this.$helpers.filterCheck(this.filesFilter, String(item.issue_visible_id))));
            })

           return filteredRows;
        },

        rows () {
            if (!this.currentCar.issues) {
                return []
            }

            if (!this.currentCar.issues.length) {
                return []
            }

            const filteredRows =  this.currentCar.issues.filter(item => {
                return item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
            })

           return filteredRows.sort(function compare(a, b) {
                if(a.tagid != 3) {
                    if (a.tagid < b.tagid)
                        return -1;
                    if (a.tagid > b.tagid)
                        return 1;
                }
                return 0;
            }).sort(function compare(a, b) {
                if( (a.tagid == 3 || a.tagid == 4) && (b.tagid != 1) ) {
                    if (a.tagid < b.tagid)
                        return 1;
                    if (a.tagid > b.tagid)
                        return -1;
                }
                return 0;
            });
        }
    },
    methods: {
        ...mapActions([
            'makeActiveTab', 'addTab', 'closeTab',
            'fetchCar', 'fetchMinCustomers', 'fetchMinMaintainers',
            'makeCurrentCus', 'makeCurrentIssue', 'makeCurrentMnt',
            'updateCar', 'deleteCar', 'uploadCarFile', 'fetchCarFiles', 'deleteCarFile', 'toggleFileVisibility'
        ]),
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },
        save(formData) {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            this.uploadCarFile({ formData: formData, id: this.currentCarId})
            .then(x => {
                this.uploadedFiles = [].concat(x);
                this.currentStatus = STATUS_SUCCESS;
                this.reset();
            })
            .catch(err => {
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;
                this.reset();
            });
        },
        filesChange(fileList) {
            // handle file changes
            const formData = new FormData();

            if (!fileList.length) return;

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                formData.append("files", fileList[x], fileList[x].name);
            });

            // save it
            this.save(formData);
        },
        deleteFile(id) {
            this.$alerts.confirmDelete('Opravdu chcete tento soubor smazat?', "").then((result) => {
                if (result.value) {
                    this.deleteCarFile(id).catch( () => {
                        this.$alerts.message('Nemáte dostatečné oprávnění', 'Soubory mohou mazat pouze jejich vlastníci', 'error')
                    });
                }
            });
        },
        toggleVisibility(id) {
            this.toggleFileVisibility({ fileid: id });
        },
        getTime(t){
            if (t < 1) {
                return null;
            }
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            const hrs = dt.getHours();
            let min = dt.getMinutes();

            if (min < 10){
              min = '0' + min.toString();
            }

            return `${day}.${mth}.${yrs}  ${hrs}:${min}`;
        },
        getDate(t){
            if (t < 1) {
                return null;
            }
            const dt = new Date(t*1000);
            const day = dt.getDate();
            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${day}.${mth}.${yrs}`;
        },
        getSTKDate(t){
            if (t < 1) {
                return null;
            }
            const dt = new Date(t*1000);

            const mth = dt.getMonth() + 1;
            const yrs = dt.getFullYear();
            return `${yrs}/${mth}`;
        },

        carUpdate(){
            if (this.$v.formResponses.$invalid)
                return 0;

            this.editTgl = false;

            this.updateCar({
                carid: this.currentCarId,
                customerid: this.formResponses.customerid,
                maintainerid: this.formResponses.maintainerid,
                name: this.formResponses.name,
                color: this.color,
                licence_plate: this.formResponses.licence_plate,
                year_made: this.yearMade,
                stk_expire: new Date(`${this.formResponses.stk_month}/10/${this.formResponses.stk_year}`).getTime()/1000,
                vin_code: this.formResponses.vin_code,
                kilometers: this.formResponses.kilometers,
                displacement: this.formResponses.displacement,
                engine_code: this.formResponses.engine_code,
                engine_power: this.formResponses.engine_power
            }).then(() => {
                this.$alerts.toast('Změny úspěšně zapsány', 'success')
                this.fetchCar(this.currentCarId)
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'));
        },

        commentUpdate(){
            if (this.$v.commentResponses.$invalid)
                return 0;

            this.updateCar({
                carid: this.currentCarId,
                comment: this.commentResponses.comment,
                hidden_comment: this.commentResponses.hidden_comment,
            }).then(() => {
                this.$alerts.toast('Změny úspěšně zapsány', 'success')
                this.fetchCar(this.currentCarId).then(() => {
                    this.commentResponses.comment = this.currentCar.comment || '';
                    this.commentResponses.hidden_comment = this.currentCar.hidden_comment || '';
                });
            }).catch(err => this.$alerts.message('Nastala chyba', err, 'error'));
        },

        carDelete(){
            this.$alerts.confirmDelete('Jste si jisti, že chcete tento záznam a všechny jeho podzáznamy odstranit?', "").then((result) => {
                if (result.value) {
                    this.deleteCar(this.currentCarId);
                    this.closeTab(this.page.id);
                }
            });
        },

        getTankStage(stage){
            switch (stage) {
                case 1:
                    return '1/4'

                case 2:
                    return '1/2'

                case 3:
                    return '3/4'

                case 4:
                    return 'Plná'

                default:
                    return 'Prázdná'

            }
        },

        addDisplacement(){
            if(this.formResponses.displacement < 500)
                this.formResponses.displacement = 1600;
            else if(this.formResponses.displacement < 7000)
                this.formResponses.displacement = parseInt(this.formResponses.displacement) + 100;
        },

        subDisplacement(){
            if(this.formResponses.displacement < 500)
                this.formResponses.displacement = 1600;
            else if(this.formResponses.displacement > 500)
                this.formResponses.displacement = parseInt(this.formResponses.displacement) - 100;
        },
        displacementNewVal(){
            if(!this.formResponses.displacement)
                this.formResponses.displacement = 1600;
        },
        
        addPower(){
            if (this.formResponses.engine_power == null)
                return null;
            if(this.formResponses.engine_power < 1000)
                this.formResponses.engine_power = parseInt(this.formResponses.engine_power) + 1;
        },

        subPower(){
            if (this.formResponses.engine_power == null)
                return null;
            if(this.formResponses.engine_power > 1)
                this.formResponses.engine_power = parseInt(this.formResponses.engine_power) - 1;
        },
        addKilometers(){
            if (this.formResponses.kilometers == null)
                return null;
            if(this.formResponses.kilometers < 1995001)
                this.formResponses.kilometers = parseInt(this.formResponses.kilometers) + 5000;
        },

        subKilometers(){
            if (this.formResponses.kilometers == null)
                return null;
            if(this.formResponses.kilometers > 5000)
                this.formResponses.kilometers = parseInt(this.formResponses.kilometers) - 5000;
        },
        editCar() {
            // Set values for update
            this.formResponses.name = this.currentCar.name;
            this.formResponses.customerid = this.currentCar.customerid;
            this.formResponses.maintainerid = this.currentCar.maintainerid;
            this.formResponses.licence_plate = this.currentCar.licence_plate || '';
            this.formResponses.engine_code = this.currentCar.engine_code || '';
            this.formResponses.vin_code = this.currentCar.vin_code || '';
            this.formResponses.kilometers = this.currentCar.kilometers;
            if (this.currentCar.stk_expire > 1){
                this.formResponses.stk_year = new Date(this.currentCar.stk_expire*1000).getFullYear();
                this.formResponses.stk_month = new Date(this.currentCar.stk_expire*1000).getMonth() + 1;
            }
            this.formResponses.displacement = this.currentCar.displacement;
            this.formResponses.engine_power = this.currentCar.engine_power;
            this.yearMade = this.currentCar.year_made;
            this.color = this.currentCar.color;

            this.editTgl = true;

            // Get customer values
            this.fetchMinCustomers();
            this.fetchMinMaintainers(this.currentCar.customerid);
        },

        getMinMnt(event){
            this.fetchMinMaintainers(event.target.value).then(() => {
                this.formResponses.maintainerid = this.minMaintainers[0].id;
            });
        }
    },
    async created(){
        this.currentStatus = STATUS_INITIAL;
        if (this.currentCarId != undefined || this.currentCarId != null){
            // Year and month options for STK and made year
            const currentYear = new Date().getFullYear();
            var i = currentYear - 5;
            for(i; i < currentYear + 6; i++){
                this.yearStkOptions.unshift(i);
            }
            i = currentYear - 70;
            for(i; i <= currentYear; i++){
                this.yearMadeOptions.unshift(i);
            }
            for(i = 1; i < 13; i++){
                this.monthStkOptions.push(i);
            }

            // Fetch data
            this.fetchCar(this.currentCarId).then(() => {
                this.commentResponses.comment = this.currentCar.comment || '';
                this.commentResponses.hidden_comment = this.currentCar.hidden_comment || '';

                this.page = this.getAllPages.filter(page => page.name == name)[0]
                this.addTab({...this.page, lng_name: this.currentCar.licence_plate});
                this.makeActiveTab(this.page);
            });
            this.fetchCarFiles(this.currentCarId);
        } else {
            router.push({ name: 'Cars' })
        }

    }
}
</script>

<style scoped>

.dropbox {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 120px;
    position: relative;
    cursor: pointer;
}
  
.input-file {
    opacity: 0;
    width: 100%;
    height: 120px;
    position: absolute;
    cursor: pointer;
}
  
.dropbox:hover {
    background: lightblue;
}
  
.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

a {
    cursor: pointer;
}
.module .collapse, .module .collapsing {
    height: 3rem;
}

.module .collapse {
    position: relative;
    display: block;
    overflow: hidden;
}

.module .collapse:before {
    content: ' ...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.module .collapse.show {
    height: auto;
}

.module .collapse.show:before {
    display: none;
}

.module a.collapsed:after {
    content: '+ Show More';
}

.module a:not(.collapsed):after {
    content: '- Show Less';
}
.help-block {
    margin-bottom: 0px;
}
.form-group {
    margin-bottom: 0px;
}
</style>